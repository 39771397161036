type TImage = {
  src: unknown;
  alt: string;
};

type TImageOptions = {
  alt: string;
  view: string;
  name: string;
  extension?: string;
};

const createImage = (imageOptions: TImageOptions): TImage => {
  const { alt, view, name, extension } = imageOptions;
  return {
    alt,
    src: require("@/assets/tabs/" + view + "/" + name + "." + (extension ?? "svg")),
  };
};

type TTabSubSection = {
  name: string;
  text: string;
};

type TTabSubOptions = {
  name: string;
  text?: string;
  image?: TImageOptions;
  sections?: TTabSubSection[];
};

type TTabSub = {
  name: string;
  text: string | null;
  image: TImage | null;
  sections: TTabSubSection[] | null;
};

type TTabOptions = {
  image: TImageOptions;
  name: string;
  description?: string;
  subs?: TTabSubOptions[];
  extraImage?: TImageOptions;
};

export type TTab = {
  image: TImage;
  name: string;
  description: string | null;
  subs: TTabSub[] | null;
  extraImage: TImage | null;
  currentSubIndex: number;
};

const createTab = (tabOptions: TTabOptions): TTab => ({
  currentSubIndex: 0,
  image: createImage(tabOptions.image),
  name: tabOptions.name,
  description: tabOptions.description ?? null,
  extraImage: tabOptions.extraImage ? createImage(tabOptions.extraImage) : null,
  subs:
    tabOptions.subs?.map((subOptions) => ({
      name: subOptions.name,
      text: subOptions.text ?? null,
      image: subOptions.image ? createImage(subOptions.image) : null,
      sections: subOptions.sections ?? null,
    })) ?? null,
});

export const createTabs = (...optionsList: TTabOptions[]) => optionsList.map(createTab);
