





























































import "vue-transition-expand/dist/vue-transition-expand.css";
import { TransitionExpand } from "vue-transition-expand";
import { Component, Vue, Prop } from "vue-property-decorator";
import { TTab } from "./models";

@Component({
  components: {
    TransitionExpand,
  },
})
export default class TabsView extends Vue {
  @Prop({ type: Number, default: -1 }) readonly value!: number;
  @Prop({ type: Array, required: true }) readonly tabs!: TTab[];
  @Prop({ type: Boolean, default: false }) readonly opacified!: boolean;

  protected get currentTabIndex() {
    return this.value;
  }
  protected set currentTabIndex(v) {
    this.$emit("input", v);
  }

  protected get hasSubs() {
    return this.tabs.every(({ subs }) => !!subs);
  }

  protected get classes() {
    return {
      subs: this.hasSubs,
      "no-subs": !this.hasSubs,
    };
  }

  protected openedTabIndexes: number[] = [];

  protected toggleTabIndex(i: number) {
    const index = this.openedTabIndexes.indexOf(i);
    if (index === -1) this.openedTabIndexes.push(i);
    else this.openedTabIndexes.splice(index, 1);
  }

  protected get reorganizedTabs() {
    return this.tabs.map((tab, i) => ({
      key: "tab:" + i,
      opened: this.openedTabIndexes.includes(i),
      selected: i === this.currentTabIndex,
      action: () => {
        this.toggleTabIndex(i);
        this.currentTabIndex = i;
      },
      ...tab,
    }));
  }

  protected get currentTab() {
    if (this.currentTabIndex === -1) return null;
    const tab = this.tabs[this.currentTabIndex];
    return {
      currentSubIndex: tab.currentSubIndex,
      image: tab.image,
      extraImage: tab.extraImage,
      name: tab.name,
      subs:
        tab.subs?.map((sub, i) => {
          const subKey = "tab:" + this.currentTabIndex + "/sub-tab:" + i;
          return {
            key: subKey,
            selected: i === tab.currentSubIndex,
            action: () => {
              tab.currentSubIndex = i;
            },
            image: sub.image,
            name: sub.name,
            text: sub.text,
            sections:
              sub.sections?.map((section, j) => {
                const sectionKey = subKey + "/section:" + j;
                return {
                  key: sectionKey,
                  ...section,
                };
              }) ?? null,
          };
        }) ?? null,
    };
  }

  protected get currentSub() {
    if (!this.currentTab) return null;
    if (!this.currentTab.subs || this.currentTab.currentSubIndex === -1) return null;
    return this.currentTab.subs[this.currentTab.currentSubIndex];
  }

  protected get currentImage() {
    return this.currentTab?.extraImage ?? this.currentSub?.image ?? null;
  }
}
